import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/sales',
        component: () => import('@/layout/BillingFormLayout.vue'),
        redirect: '/sales/end-user-data-form',
        children: [
            {
                path: 'end-user-data-form',
                name: 'WaitingBillingFormView',
                component: () => import('@/views/EndUserDataFormView.vue'),
            },
            {
                path: 'end-user-data-form/buat',
                name: 'CreateEndUserDataFormView',
                component: () => import('@/views/CreateEndUserDataFormView.vue'),
            },
            {
                path: 'end-user-data-form/edit',
                name: 'EditEndUserDataFormView',
                component: () => import('@/views/EditEndUserDataFormView.vue'),
            },
            {
                path: 'end-user-data-form/detail',
                name: 'DetailEndUserDataFormView',
                component: () => import('@/views/DetailEndUserDataFormView.vue'),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
